import React, { Suspense, lazy } from "react";
import "normalize.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loader from "./components/Loader";
import "./sass/main.scss";
const Home = lazy(() => import("./screens/home"));
const About = lazy(() => import("./screens/about"));
const Careers = lazy(() => import("./screens/careers"));
const Contact = lazy(() => import("./screens/contact"));
const Team = lazy(() => import("./screens/team"));
const PracticeArea = lazy(() => import("./screens/practice-area"));

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
              <Route path="/careers" component={Careers} />
              <Route path="/team" component={Team} />
              <Route path="/practice-area" component={PracticeArea} />
            </Switch>
          </Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
