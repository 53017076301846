import React, { Component, Fragment } from "react";

export default class Loader extends Component {
  render() {
    return (
      <Fragment>
        <div className="loader-wrapper">
          <img src="/asset/img/logo.png" alt="logo loader" />
        </div>
      </Fragment>
    );
  }
}
